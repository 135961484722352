import React from "react"
import { graphql } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useMediaQuery } from "@material-ui/core"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import LineTitle from "../components/LineTitle"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Youtube from "../components/Video/Youtube"
// import QAAccordion from "../components/accordion"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
    },
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
      height: "42vh",
    },
  },
  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  bodyHeader: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 600,
    // color: "#202020",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  bodyText: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },
  bodyText2: {
    fontSize: "1.1rem",
    textAlign: "left",
    // color: "#202020",
    color: "white",
    maxWidth: 600,
    whiteSpace: "pre-line",
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  introHeader: {
    textAlign: "left",
    paddingTop: 60,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "60px",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "auto",
      paddingBottom: "auto",
    },
  },
  tooLongtitle: {
    color: "white",
    maxWidth: 200,
    ["@media (min-width:1440px)"]: {
      fontSize: "5rem",
    },
    ["@media (max-width:600px)"]: {
      fontSize: "2rem",
    },
  },
  tooLongSubheader: {
    color: "#828282",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    width: "750px",
    ["@media (max-width:600px)"]: {
      fontSize: "1rem",
      width: "270px",
    },
  },
  videoText: {
    textAlign: "left",
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  sloganContainer: {
    alignSelf: "center",
    fontWeight: 300,
  },
  backgroundImg: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right",
      height: 260,
    },
  },
  imageContainer: {
    alignSelf: "center",
  },
  videoContainer: {
    paddingTop: 60,
    maxHeight: 400,
    alignSelf: "center",
  },
  sectionContainer: {
    paddingTop: 80,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  sectionContainerExplanation: {
    paddingBottom: 30,
    background: "white",
    background: "#343640",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  sectionContainerExplanationLast: {
    paddingBottom: 30,
    background: "white",
    background: "#343640",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 60,
    },
  },
  layers: {
    maxWidth: 500,
    alignSelf: "center",
    [theme.breakpoints.down("md")]: {
      width: 340,
    },
  },
}))

const Rhinoplasty = ({ intl, data, location }) => {
  const classes = useStyles()
  const theme = useTheme()
  const t = (id) => intl.formatMessage({ id })
  const banner = data.bannerImage.childImageSharp.fluid
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "rhinoplasty.SEOtitle" })}
        description={intl.formatMessage({ id: "rhinoplasty.SEO" })}
        path={location.pathname}
      />
      <MainToolbar black />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <BackgroundImage
          fluid={banner}
          className={classes.backgroundImg}
          backgroundColor={`#333`}
        >
          <Grid container spacing={0} className={classes.bannerGrid}>
            <Grid item xs={12} sm={4} className={classes.bannerLeft}>
              <Container>
                <LineTitle
                  header={t("rhinoplasty.title")}
                  subheader={t("rhinoplasty.subtitle")}
                  titleContainer={classes.titleContainer}
                  titleStyle={classes.tooLongtitle}
                  lightDecoLine
                  subHeaderStyle={classes.tooLongSubheader}
                />
              </Container>
            </Grid>
            <Grid item xs={12} sm={8}></Grid>
          </Grid>
        </BackgroundImage>
        <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid item xs={12} sm={6} align="center">
            <Typography
              component="h2"
              variant="h4"
              className={classes.introHeader}
            >
              {t("rhinoplasty.introTitle")}
            </Typography>
            <p className={classes.bodyText}>{t("rhinoplasty.introText")}</p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            className={classes.imageContainer}
          >
            <Img
              fluid={data.front.childImageSharp.fluid}
              alt="First fixations areas"
              className={classes.beforeAfter}
            ></Img>
          </Grid>
        </Grid>

        <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            className={classes.videoContainer}
          >
            <Youtube
              videoUrl="https://www.youtube.com/watch?v=LIsB5GOu0pM"
              imgFluid={data.explained.childImageSharp.fluid}
            ></Youtube>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            className={classes.sloganContainer}
          >
            <Typography
              component="h2"
              variant="h4"
              className={classes.videoText}
            >
              {t("rhinoplasty.videoText")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid item xs={12} sm={6} align="center">
            {/* <QAAccordion></QAAccordion> */}
          </Grid>
          <Grid item xs={12} sm={6} align="center"></Grid>
        </Grid>

        <Grid
          container
          spacing={12}
          direction={smallScreen ? "column-reverse" : "row"}
          className={classes.sectionContainerExplanation}
        >
          <Grid item xs={12} sm={8} align="center">
            <Typography
              component="h2"
              variant="h4"
              className={classes.bodyHeader}
            >
              {t("rhinoplasty.explainedTitle")}
            </Typography>
            <p className={classes.bodyText2}>{t("rhinoplasty.introExplain")}</p>
          </Grid>
          <Grid item xs={12} sm={4} align="center"></Grid>
        </Grid>

        <Grid
          container
          spacing={12}
          className={classes.sectionContainerExplanation}
        >
          <Grid item xs={12} sm={8} align="center">
            <Typography
              component="h2"
              variant="h4"
              className={classes.bodyHeader}
            >
              {t("rhinoplasty.faceStructuresTitle")}
            </Typography>
            <p className={classes.bodyText2}>
              {t("rhinoplasty.faceStructures")}
            </p>
            <Typography
              component="h2"
              variant="h4"
              className={classes.bodyHeader}
            >
              {t("rhinoplasty.rhinoTitle2")}
            </Typography>
            <p className={classes.bodyText2}>{t("rhinoplasty.rhinoSub2")}</p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            align="center"
            className={classes.imageContainer}
          >
            <Img
              fluid={data.beforeAfter.childImageSharp.fluid}
              alt="Rhinoplasty before and after"
              className={classes.layers}
            ></Img>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={12}
          className={classes.sectionContainerExplanation}
        >
          <Grid item xs={12} sm={8} align="center">
            <Typography
              component="h2"
              variant="h4"
              className={classes.bodyHeader}
            >
              {t("rhinoplasty.rhinoTitle3")}
            </Typography>
            <p className={classes.bodyText2}>
              {t("rhinoplasty.rhinoSubTitle3")}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            align="center"
            className={classes.videoContainer}
          >
            {/* <Youtube
              videoUrl="https://www.youtube.com/watch?v=CrU9DIvE3dA"
              imgFluid={data.explained.childImageSharp.fluid}
            ></Youtube> */}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={12}
          className={classes.sectionContainerExplanationLast}
        >
          <Grid
            item
            xs={12}
            sm={8}
            align="center"
            className={classes.imageContainer}
          >
            <Typography
              component="h2"
              variant="h4"
              className={classes.bodyHeader}
            >
              {t("rhinoplasty.rhinoTitle4")}
            </Typography>
            <p className={classes.bodyText2}>
              {t("rhinoplasty.rhinoSubTitle4")}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            align="center"
            className={classes.imageContainer}
          ></Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default injectIntl(Rhinoplasty)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "HeroBannerRhinoplasty2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    front: file(relativePath: { eq: "first-look.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter: file(relativePath: { eq: "rhinoplasty-before-after.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    explained: file(relativePath: { eq: "glenda.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
